import './styles.scss'
import TrainPartnes from './TrainPartners'
import DicasTreino from './DicasTreino'
import CompleteTrains from './CompleteTrains'
import TrainInHouse from './TrainInHouse'

const TreinoEmCasaComponent = () => {
  return (
    <main className="lp-treino-em-casa">
      <img
        src="https://decathlonpro.vteximg.com.br/arquivos/banner-desktop-treino-em-casa.png"
        alt="Treino em Casa | Decathlon"
      />
      <TrainPartnes />
      <DicasTreino />
      <CompleteTrains />
      <TrainInHouse />
    </main>
  )
}

export default TreinoEmCasaComponent
